<script setup>
import {useStore} from 'vuex'
import { computed } from 'vue'

console.log("init Stats")
const store = useStore()
let stats = computed(function () {
  return store.state.stats
});
store.commit('updateStat')

</script>
<template>
  <div>
    <h1>Статистика сервиса штрафов</h1>
    <el-row>
      <el-col :span="12">
        <div class="card">
          <h2>Штрафы сегодня</h2>
          <p>
            Обработано: {{ stats.monitoring.today.fines.processed }}<br/>
            Ошибка: {{ stats.monitoring.today.fines.failed }}<br/>
            Не обработаны: {{ stats.monitoring.today.fines.new }}<br/>
            Всего: {{ stats.monitoring.today.fines.total }}
          </p>
        </div>
        <div class="card">
          <h2>Фото сегодня</h2>
          <p>
            Всего: {{ stats.monitoring.today.photos.total }}<br/>
            Не найдены: {{ stats.monitoring.today.photos.notFound }}<br/>
            Не обработаны: {{ stats.monitoring.today.photos.new }}<br/>
            Найдено в GIS: {{ stats.monitoring.today.photos.gis }}<br/>
            Найдено в A3: {{ stats.monitoring.today.photos.external }}<br/>
          </p>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="card">
          <h2>Штрафы сегодня</h2>
          <p>
            Обработано: {{ stats.monitoring.total.fines.processed }}<br/>
            Ошибка: {{ stats.monitoring.total.fines.failed }}<br/>
            Не обработаны: {{ stats.monitoring.total.fines.new }}<br/>
            Всего: {{ stats.monitoring.total.fines.total }}
          </p>
        </div>
        <div class="card">
          <h2>Фото сегодня</h2>
          <p>
            Всего: {{ stats.monitoring.total.photos.total }}<br/>
            Не найдены: {{ stats.monitoring.total.photos.notFound }}<br/>
            Не обработаны: {{ stats.monitoring.total.photos.new }}<br/>
            Найдено в GIS: {{ stats.monitoring.total.photos.gis }}<br/>
            Найдено в A3: {{ stats.monitoring.total.photos.external }}<br/>
          </p>
        </div>
      </el-col>
    </el-row>

    <div class="card">
      <h2>Машины в сервисе</h2>
      <p>
        Всего машин: {{ stats.cars }}<br/>
      </p>
    </div>

    <div class="card">
      <h2>Клиентские услуги</h2>
      <p>
        Активны: {{ stats.services.active }}<br/>
        Неактивны: {{ stats.services.inactive }}<br/>
        Заблкированы: {{ stats.services.blocked }}<br/>
      </p>
    </div>
  </div>

</template>

<style scoped>
.card {
  text-align: left;
}
</style>
