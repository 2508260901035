import {createRouter, createWebHistory} from 'vue-router'

import Stats from "../components/Stats";

const routes = [
    {path: '/', component: Stats, name: "Stats"},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;
