<template>
  <div id="app">
    <el-menu  router="true"  style="justify-content:center" class="el-menu" id="el-menu"
             mode="horizontal">
      <el-menu-item index="1-1-0" :route="link('Stats')">Stats</el-menu-item>

    </el-menu>
    <br/>
    <el-row align="middle">
      <el-col align="middle" style="text-align: left" :offset="1" :span="22">
        <router-view></router-view>
      </el-col>
    </el-row>

  </div>
</template>

<script>


import router from "./router/index";

export default {
  name: 'App',
  data: function () {
    return {
      pageIcon: ""
    }
  },
  components: {},
  methods: {
    link(name) {
      return router.resolve({name: name})
    }
  }
}
</script>
<style>
.logout {
  position: absolute;
  right: 15px;
  top: 5px;
  z-index: 111111;
}

#el-menu .el-sub-menu, #el-menu.el-menu--horizontal > .el-menu-item, #el-menu.el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  height: 40px !important;
  line-height: 30px !important;
}

.menu-item {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}
h3{
  margin-top:0px !important;
}
</style>
