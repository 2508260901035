//import {reactive} from "vue";

import axiosApiInstance from "./axios";
import {createStore} from 'vuex'
import VuexPersistence from "vuex-persist";

// function uuidv4() {
//     return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
//         (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
//     );
// }

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});
export const store = createStore({
    plugins: [vuexLocal.plugin],
    state() {
        return {
            stats: {
                cars:0,
                monitoring: {
                    "today": {
                        "fines": {
                            "failed": 0,
                            "processed": 0,
                            "total": 0
                        },
                        "photos": {
                            "external": 0,
                            "gis": 0,
                            "new": 0,
                            "notFound": 0,
                            "total": 0
                        }
                    },
                    "total": {
                        "fines": {
                            "failed": 0,
                            "processed": 0,
                            "total": 0
                        },
                        "photos": {
                            "external": 0,
                            "gis": 0,
                            "new": 0,
                            "notFound": 0,
                            "total": 0
                        }
                    }
                },

                "services": {
                    "active": 16,
                    "blocked": 0,
                    "inactive": 2
                }

            }
        }
    },
    mutations: {
        updateStat(state) {

            axiosApiInstance.get((process.env.VUE_APP_FINES_SERVICE_URL ? process.env.VUE_APP_FINES_SERVICE_URL : "https://gw-stg.resheno.xyz/stage/fines/api/v1") + "/stats").then((response) => {
                state.stats = response.data
            }).catch(function (error) {
                console.log(error)
            })
        },
    },
    getters: {
        stats: state => {
            return state.stats
        },
    }
})


export default store
